import logo from './logo.svg';
import './App.css';
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <FlipClockCountdown
          to={1726812600000}
          labels={['DAYS', 'HOURS', 'MINUTES', 'SECONDS']}
          labelStyle={{ fontSize: 10, fontWeight: 500, textTransform: 'uppercase' }}
          digitBlockStyle={{ width: 40, height: 60, fontSize: 30, background: '#390752' }}
          separatorStyle={{ color: 'purple', size: '6px' }}
          duration={0.5}
        />
      </header>
    </div>
  );
}

export default App;
